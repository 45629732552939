'use client'

import httpClient from '@apiClient'
import { getUUID } from '@plugin/login'
import { usePathname } from 'next/navigation'
import { useMount } from 'react-use'

export default function ErrorLog({
  error
}) {

  const pathName = usePathname()
  useMount(() => {
    httpClient.post('app-log', {
      category: 'web',
      message: `pathName:${pathName}; uuid:${getUUID()}; error:${error}`
    })
  })
}