'use client'

import { useEffect } from 'react'

export default function BackReload() {

  function reload() {
    window.location.reload()
  }

  useEffect(() => {
    window.onpopstate = reload
    return () => {
      window.onpopstate = undefined
    }
  }, [])
}