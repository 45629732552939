'use client'

import gsap from 'gsap'
import { useEffect } from 'react'

export default function Noise({
  children
}) {

  useEffect(() => {
    const noise = document.querySelector('#noise')
    const turbulence = noise.querySelector('feTurbulence')

    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0,
      paused: true
    })

    tl.to(turbulence, {
      duration: 1.5,
      attr: {
        baseFrequency: '0.00001 0'
      },
      ease: 'none'
    })
    tl.to(turbulence, {
      duration: 0.3,
      attr: {
        baseFrequency: '0.3 0'
      },
      ease: 'bounce'
    })
    tl.play()
  }, [])

  return (
    <>
      <div style={{ filter: "url(#noise)" }}>
        {children}
      </div>
      <svg style={{ display: 'none' }}>
        <defs>
          <filter id="noise" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
            <feTurbulence type="fractalNoise" baseFrequency="0 0" numOctaves="2" seed="2" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" result="turbulence" />
            <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="20" xChannelSelector="R" yChannelSelector="B" x="0%" y="0%" width="100%" height="100%" result="displacementMap" />
          </filter>
        </defs>
      </svg>
    </>
  )
}